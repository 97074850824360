import React from "react";
import Seo from "../components/seo";

const PrivacyPage = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <Seo />
      <h1>My PrEP</h1>
      <h2>Engagement de confidentialité</h2>
      <h3>Si vous n’êtes pas abonné.e à My PrEP+</h3>
      <p>
        Aucune donnée n’est collectée. L’application n’envoie aucune donnée sur
        internet. L’historique des prises de comprimés est stocké en local dans
        votre téléphone. Aucun tracker n’est actif dans l’application.
      </p>
      <h3>Si vous êtes abonné.e à My PrEP+</h3>
      <p>
        La seule information collectée est l’historique de votre abonnement
        My PrEP+, par le SDK revenuecat.com, et ce de façon anonyme. C’est tout.
        À part ça, aucune autre information n’est collectée. L’historique de vos
        rapports est stocké en local dans votre téléphone.
      </p>
      <h3>Comment effacer mes données ?</h3>
      <p>
        Pour supprimer vos données, appuyez sur "Effacer toutes les données"
        depuis l’onglet Réglages de l’app, ou bien désinstallez simplement
        celle-ci.
      </p>
    </div>
  );
};

export default PrivacyPage;
